import React, { Component, Fragment } from 'react';
import Animation from '../components/Animation';
class Contact extends Component {
  render() {
    return (
      <Fragment>
        <div className="contact--container">
          <div className="contact--copy typewriter">
            <h1 class="">Find out more.</h1>
            <h1 class="--type-effect">Get in touch...</h1>
            <div>
              <Animation/>
            </div>
          </div>
          <div className="contact--social-container">
            <div className="social--grid">
              <div className="social--copy">
                <a
                  href="mailto:sandynism@gmail.com?Subject=Quick%20hello"
                  target="_top"
                  className=""
                >
                  <i className="fas fa-envelope" />
                  <h2>sandynism@gmail.com</h2>
                </a>
              </div>
              <div className="social--copy">
                <i className="fas fa-globe-americas" />
                <h2>New York City</h2>
              </div>
              <div className="social--copy">
                <a
                  href="https://www.linkedin.com/in/sandy-yeung-94614890"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                  <h2>sandy-yeung-94614890</h2>
                </a>
              </div>
              <div className="social--copy">
                <a href="https://github.com/sandynism" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-github" />
                  <h2>github.com/sandynism</h2>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Contact;
