import React, { Component, Fragment } from 'react';

class ProjectCard extends Component {
  render() {
    const { info } = this.props;
    return (
      <Fragment>
        <div className="project-card">
          {info.link ? (
            <>
              <a href={info.link} target="_blank" rel="noopener noreferrer">
              <h5>{info.details}</h5>
              <img src={info.image} alt={info.name} />
                <h4>{info.name}</h4>
              </a>
            </>
          ) : (
            <>
              <h5>{info.details}</h5>
              <img src={info.image} alt={info.name} />
              <h4>{info.name}</h4>
            </>
          )}
        </div>
      </Fragment>
    );
  }
}

export default ProjectCard;
