import React, { Component, Fragment } from 'react';
import heroImage from '../assets/IMG_5953.webp';

const date = new Date();
const currentTime = date.getHours();

let greeting;

if (currentTime >= 0 && currentTime < 12) {
  greeting = 'Good Morning';
} else if (currentTime >= 12 && currentTime < 18) {
  greeting = 'Good Afternoon';
} else {
  greeting = 'Good Evening';
}

class About extends Component {
  render() {
    return (
      <Fragment>
        <div className="hero--animation">
          <h3 className="hero--text-overlay gold">
            {greeting}! 
            <br></br>
            My name is Sandy Yeung.
          </h3>
        </div>
        <div className="hero--wrapper">
          <div className="hero--image-overlay"></div>
          <img className="hero--image" src={heroImage} alt="Sandy" />
        </div>

        <div className="resume">
          <div className="resume--header">
            <span className="gold resume--gold-header">
              <i className="fas fa-folder resume--header-icon" />
              <a
                href="https://www.linkedin.com/in/sandy-yeung-94614890"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fullstack Engineer- @SandyYeung ~
              </a>
            </span>
          </div>
          <div className="resume--body">
            <div className="label">> Sandy.resume</div>
            <div className="indent">
              <span className="secondary-pink"> => </span>{' '}
              <span className="white"> " </span>
              <a
                className="resume--link"
                href="https://drive.google.com/file/d/1cBHppkdsSXC7paWjyUjIkEybrxpMKQU1/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                sandy-yeung.pdf
                <i className="fa fa-download primary-blue" aria-hidden="true" />
              </a>
              <span className="white"> " </span>
            </div>

            <div className="label">> Sandy.skills</div>
            <div className="indent primary-pink">
              <span className="secondary-pink"> => </span> Skill Set
              <div className="indent--double primary-blue">
                <span className="white"> languages: </span>
                <span className="primary-yellow"> &#91; </span>
                <span className="white"> " </span>
                JavaScript<span className="white"> " </span>{' '}
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>
                Java<span className="white"> " </span>
                <span className="primary-yellow"> &#93; </span>
                <br />
                <span className="white"> skills: </span>{' '}
                <span className="primary-yellow"> &#91; </span>
                <span className="white"> " </span>TypeScript
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>React
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Next.js
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Gatsby
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Node.js
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>jQuery
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Liquid
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>
                mySQL
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>{' '}
                <span className="white"> " </span>
                GraphQL
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>
                Accessibility
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>{' '}
                <span className="white"> " </span>HTML/CSS
                <span className="white"> " </span>{' '}
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Responsive UI Design
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Styled Components
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Sass
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Git
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>
                Contentful
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>
                Sanity
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>{' '}
                <span className="white"> " </span>
                WordPress
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>SEO
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Figma
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <span className="white"> " </span>Invision
                <span className="white"> " </span>
                <span className="primary-yellow"> &#93; </span>
              </div>
            </div>

            <div className="label">> Sandy.work.latest</div>
            <div className="indent primary-pink">
              <span className="secondary-pink"> => </span> Current Work
              Experience
              <div className="indent--double primary-blue">
                <div className="primary-yellow ml-20"> &#123; </div>
                <span className="white"> company: </span>{' '}
                <span className="white"> " </span>
                <a
                  className="resume--link"
                  href="https://bighuman.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Big Human
                </a>
                <span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <br />
                <span className="white"> role: </span>
                <span className="white"> " </span>Senior Software Engineer
                <span className="white"> " </span>
                <br />
                <div className="primary-yellow ml-20"> &#125; </div>
              </div>
            </div>
            <div className="indent primary-pink">
              <span className="secondary-pink"> => </span> Past Work Experience
              <div className="indent--double primary-blue">
                <div className="primary-yellow ml-20"> &#123; </div>
                <span className="white"> company: </span>{' '}
                <span className="white"> " </span>
                Northwestern Mutual<span className="white"> " </span>
                <span className="primary-yellow"> , </span>
                <br />
                <span className="white"> role: </span>
                <span className="white"> " </span>Development Coordinator
                <span className="white"> " </span>
                <br />
                <div className="primary-yellow ml-20"> &#125; </div>
              </div>
            </div>

            <div className="label">> Sandy.education</div>
            <div className="indent primary-blue">
              <span className="secondary-pink"> => </span>
              <span className="primary-yellow"> &#91; </span>
              <span className="white"> " </span> Columbia University
              <span className="white"> " </span>
              <span className="primary-yellow"> , </span>
              <span className="white"> " </span>St Johns University
              <span className="white"> " </span>
              <span className="primary-yellow"> &#93; </span>
            </div>

            <div className="label">> Sandy.interests</div>
            <div className="indent primary-blue">
              <span className="secondary-pink"> => </span>
              <span className="primary-yellow"> &#91; </span>
              <span className="white"> " </span>Music
              <span className="white"> " </span>
              <span className="primary-yellow"> , </span>
              <span className="white"> " </span>Tea
              <span className="white"> " </span>
              <span className="primary-yellow"> , </span>{' '}
              <span className="white"> " </span>
              Animals
              <span className="white"> " </span>
              <span className="primary-yellow"> , </span>{' '}
              <span className="white"> " </span>Hiking
              <span className="white"> " </span>
              <span className="primary-yellow"> , </span>{' '}
              <span className="white"> " </span>Travel
              <span className="white"> " </span>
              <span className="primary-yellow"> , </span>{' '}
              <span className="white"> " </span>
              Beautiful UI/UX
              <span className="white"> " </span>
              <span className="primary-yellow"> &#93; </span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default About;
