import React, { Component, Fragment } from 'react';
import schoolProjects from '../schoolProjects.json';
import workProjects from '../workProjects.json';
import ProjectCard from '../components/ProjectCard';

class Portfolio extends Component {
  render() {
    return (
      <Fragment>
        <div className="portfolio--section">
          <h2 className="portfolio--header">Professional Projects</h2>
          <div className="portfolio--grid-container">
            {workProjects.map((card, i) => {
              return <ProjectCard key={i} info={card} />;
            })}
          </div>
        </div>
        <hr></hr>
        <div className="portfolio--section">
          <h2 className="portfolio--header">Just for fun</h2>
          <div className="portfolio--grid-container">
            {schoolProjects.map((card, i) => {
              return <ProjectCard key={i} info={card} />;
            })}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Portfolio;
