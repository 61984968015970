import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const links = [{ About: '/about' }, { Portfolio: '/portfolio' }, { Contact: '/contact' }];

class Navbar extends Component {
  state = {
    activeLink: 'About'
  };

  displayLinks = () => {
    const path = window.location.pathname

    return links.map((link, idx) => {
      const linkLabel = Object.keys(link)[0]
      const linkUrl = Object.values(link)[0]

      return (
        <li className="nav-item" key={`key-${idx}`}>
          <Link
            to={linkUrl}
            className={'nav-link ' + (path === linkUrl || this.state.activeLink === linkLabel ? 'active' : '')}
            onClick={() => this.changeActive(linkLabel)}
          >
            {linkLabel}
          </Link>
        </li>
      );
    });
  };

  changeActive = name => {
    this.setState({
      activeLink: name
    });
  };

  render() {

    return (
      <nav className="navbar navbar-expand-md navbar-light bg-light">
        <a className="navbar-brand logo" href="/">
          SY
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            {this.displayLinks()}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
