import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './templates/About';
import Portfolio from './templates/Portfolio';
import Contact from './templates/Contact';
import Navbar from './components/Navbar';
import './App.scss';

class App extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          <Navbar />
          <Switch>
            <Route path="/Portfolio" component={Portfolio} />
            <Route path="/Contact" component={Contact} />
            <Route component={About} />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

export default App;
